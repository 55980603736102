export const data = {
  vocabulary: [
    { id: 1, phrase: "Salds sapnis" },
    { id: 2, phrase: "Laimīga diena" },
    { id: 3, phrase: "Pūķa spēks" },
    { id: 4, phrase: "Dzeltens zieds" },
    { id: 5, phrase: "Siltā sajūta" },
    { id: 6, phrase: "Melns vilnis" },
    { id: 7, phrase: "Zilā debess" },
    { id: 8, phrase: "Sniega pārslas" },
    { id: 9, phrase: "Mierīgs upe" },
    { id: 10, phrase: "Baltais sniegs" },
    { id: 11, phrase: "Mājīgs siltums" },
    { id: 12, phrase: "Tumša nakts" },
    { id: 13, phrase: "Jauna iespēja" },
    { id: 14, phrase: "Zeltainais saules rietums" },
    { id: 15, phrase: "Dzeltenie lauki" },
    { id: 16, phrase: "Mierīgs rīts" },
    { id: 17, phrase: "Skaista dziesma" },
    { id: 18, phrase: "Brīvības sajūta" },
    { id: 19, phrase: "Mazais ceļš" },
    { id: 20, phrase: "Zaļš mežs" },
    { id: 21, phrase: "Siltas rozes" },
    { id: 22, phrase: "Vecs draugs" },
    { id: 23, phrase: "Jauna pieredze" },
    { id: 24, phrase: "Draudzīga roka" },
    { id: 25, phrase: "Dzeltenas lapas" },
    { id: 26, phrase: "Lielais plāns" },
    { id: 27, phrase: "Skaista ainava" },
    { id: 28, phrase: "Mākslas darbs" },
    { id: 29, phrase: "Lielais sapnis" },
    { id: 30, phrase: "Jauna sākuma" },
    { id: 31, phrase: "Brīnumainas pēdas" },
    { id: 32, phrase: "Mierīgs vakars" },
    { id: 33, phrase: "Skaists skats" },
    { id: 34, phrase: "Draudzīga smaile" },
    { id: 35, phrase: "Siltās sajūtas" },
    { id: 36, phrase: "Vecās tradīcijas" },
    { id: 37, phrase: "Jaunas idejas" },
    { id: 38, phrase: "Lieliskas izpriecas" },
    { id: 39, phrase: "Mīļš skolotājs" },
    { id: 40, phrase: "Rotaļu laiks" },
    { id: 41, phrase: "Aizraujošs ceļojums" },
    { id: 42, phrase: "Dzīves prieks" },
    { id: 43, phrase: "Siltie apskāvieni" },
    { id: 44, phrase: "Brīvā gaisma" },
    { id: 45, phrase: "Lieliskas atmiņas" },
    { id: 46, phrase: "Aizraujošs ceļš" },
    { id: 47, phrase: "Brīnišķīga mūzika" },
    { id: 48, phrase: "Mākslinieciskas rokas" },
    { id: 49, phrase: "Iepriecinošs smaids" },
    { id: 50, phrase: "Siltas saulītes" },
    { id: 51, phrase: "Skaistas ainavas" },
    { id: 52, phrase: "Mierīgi vakari" },
    { id: 53, phrase: "Radošas idejas" },
    { id: 54, phrase: "Piedzīvojumi dabā" },
    { id: 55, phrase: "Laimīgas brīvdienas" },
    { id: 56, phrase: "Satriecošas izjūtas" },
    { id: 57, phrase: "Pateicības sajūta" },
    { id: 58, phrase: "Izcili talanti" },
    { id: 59, phrase: "Viduslaiku pasaule" },
    { id: 60, phrase: "Mīļš draugs" },
    { id: 61, phrase: "Radoša izpausme" },
    { id: 62, phrase: "Draudzīgi saspēles" },
    { id: 63, phrase: "Iepriecinošs brīdis" },
    { id: 64, phrase: "Skaisti saulrieti" },
    { id: 65, phrase: "Mierīgs daba" },
    { id: 66, phrase: "Siltas atvases" },
    { id: 67, phrase: "Satriecošs piedzīvojums" },
    { id: 68, phrase: "Lielisks atbalsts" },
    { id: 69, phrase: "Dzīves enerģija" },
    { id: 70, phrase: "Pieredzes bagātinājums" },
    { id: 71, phrase: "Skaists sirdsskaņas" },
    { id: 72, phrase: "Izglītības ceļš" },
    { id: 73, phrase: "Priecīgas smiekli" },
    { id: 74, phrase: "Miers un līdzsvars" },
    { id: 75, phrase: "Pārsteidzošas atklāsmes" },
    { id: 76, phrase: "Brīvā debess" },
    { id: 77, phrase: "Sirds siltums" },
    { id: 78, phrase: "Draudzīga saruna" },
    { id: 79, phrase: "Aizraujošs ceļojums" },
    { id: 80, phrase: "Mākslinieciska izpausme" },
    { id: 81, phrase: "Iedvesmojošs skatījums" },
    { id: 82, phrase: "Skaisti svētki" },
    { id: 83, phrase: "Piedzīvojumu gars" },
    { id: 84, phrase: "Lielisks iedvesmas avots" },
    { id: 85, phrase: "Mīlestības sasniegums" },
    { id: 86, phrase: "Izcila sniegšana" },
    { id: 87, phrase: "Brīvā laika baudījums" },
    { id: 88, phrase: "Savstarpēja sapratne" },
    { id: 89, phrase: "Aizraujoša izpēte" },
    { id: 90, phrase: "Skaista harmonija" },
    { id: 91, phrase: "Izcils talants" },
    { id: 92, phrase: "Siltas sajūtas" },
    { id: 93, phrase: "Mierīgs vakara gājiens" },
    { id: 94, phrase: "Skaisti sapņi" },
    { id: 95, phrase: "Draudzīgas saspēles" },
    { id: 96, phrase: "Atpūtas brīdis" },
    { id: 97, phrase: "Iedvesmojošs ceļš" },
    { id: 98, phrase: "Laimīga sirds" },
    { id: 99, phrase: "Radošs spēks" },
    { id: 100, phrase: "Pieredzes bagātinājums" },
    { id: 101, phrase: "Satriecoša mūzika" },
    { id: 102, phrase: "Izcila komanda" },
    { id: 103, phrase: "Brīvības sajūta" },
    { id: 104, phrase: "Silti apsveikumi" },
    { id: 105, phrase: "Lieliska izaugsme" },
  ],
};
