export const alphabet = [
  " ",
  "A",
  "Ā",
  "B",
  "C",
  "Č",
  "D",
  "E",
  "Ē",
  "F",
  "G",
  "Ģ",
  "H",
  "I",
  "Ī",
  "J",
  "K",
  "Ķ",
  "L",
  "Ļ",
  "M",
  "N",
  "Ņ",
  "O",
  "P",
  "R",
  "S",
  "Š",
  "T",
  "U",
  "Ū",
  "V",
  "Z",
  "Ž",
];
